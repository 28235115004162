<template>
	<div class="page_content">
		<div class="page_detail">
			<a-divider orientation="left">基本信息</a-divider>
			<a-descriptions :column="2">
				<a-descriptions-item label="名称">
					{{ detail.seller_name||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="类型">
					{{ detail.flag == 1 ? '经销商' : (detail.flag == 2 ? '分销商' : '仓管') }}
				</a-descriptions-item>

				<a-descriptions-item label="配送类型">

					<!-- <a-radio-group name="seller_flag" v-model="detail.seller_flag">
						<a-radio :value="1">承运/自提</a-radio>
						<a-radio :value="2">统一配送</a-radio>
					</a-radio-group> -->

                    <a-checkbox-group name="seller_flag" v-model="detail.seller_flag">
                        <a-checkbox :value="1">承运/自提</a-checkbox>
                        <a-checkbox :value="2">统一配送</a-checkbox>
                    </a-checkbox-group>
				</a-descriptions-item>

				<a-descriptions-item :label="detail.flag==1?'经销商编号':(detail.flag==2?'分销商编号':'仓库编号')">
					{{ detail.seller_id||'--' }}
				</a-descriptions-item>
				<a-descriptions-item :label="detail.flag==1?'SAP编号':'上级经销商'" v-if="detail.flag!=3">
					{{ detail.flag==1?detail.sap_seller_id:detail.parent_name }}
				</a-descriptions-item>
				<a-descriptions-item label="法人" v-if="detail.flag!=3">
					{{ detail.principal||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="电话">
					<a-input v-model:value="detail.phone" style="width: 250px;"></a-input>
				</a-descriptions-item>
				<a-descriptions-item label="所属省份" v-if="detail.flag!=3">
					{{ detail.province||'--'}}
				</a-descriptions-item>
				<a-descriptions-item label="所属机构" v-if="detail.flag!=3">
					{{ detail.city||'--'}}
				</a-descriptions-item>
				<a-descriptions-item label="机构ID" v-if="detail.flag!=3">
					{{ detail.organization_id||'--'}}
				</a-descriptions-item>
				<a-descriptions-item label="地县名称" v-if="detail.flag!=3">
					{{ detail.district||'--'}}
				</a-descriptions-item>
				<a-descriptions-item label="地址" v-if="detail.flag!=3">
					{{ detail.address||'--'}}
				</a-descriptions-item>
				<a-descriptions-item label="绑定区域">
					<!-- <a-input v-model:value="detail.department_name" style="width: 250px;"></a-input> -->
					<a-tree-select v-model:value="detail.department_id" style="width: 250px;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="Please select"
						allow-clear :tree-data="department_tree">
					</a-tree-select>
				</a-descriptions-item>
				<a-descriptions-item label="绑定微信">
					<a-input v-model:value="detail.openid" style="width: 300px;"></a-input>
				</a-descriptions-item>
				<a-descriptions-item label="是否范围内送达" v-if="detail.flag == 2">
					<a-radio-group name="need_in_range" v-model:value="detail.need_in_range">
						<a-radio value="1">是</a-radio>
						<a-radio value="-1">否</a-radio>
					</a-radio-group>
				</a-descriptions-item>

				<a-descriptions-item label="司机自提权限">
					<a-radio-group name="self_pickup_auth" v-model:value="detail.self_pickup_auth">
						<a-radio :value="1">是</a-radio>
						<a-radio :value="0">否</a-radio>
					</a-radio-group>
				</a-descriptions-item>
			</a-descriptions>

			<a-divider orientation="left" v-if="detail.flag==1">账户信息</a-divider>
			<div class="table_box" v-if="detail.flag==1">
				<div class="tr_box">
					<div>信用额度</div>
					<div>账上金额</div>
					<div>保证金</div>
					<div>可用余额</div>
					<div>未出货金额</div>
				</div>
				<div>
					<div class="td_box">
						<div>{{ detail.credit_count }}</div>
						<div>{{ detail.seller_count }}</div>
						<div>{{ detail.bond_money }}</div>
						<div>{{ detail.remain_account }}</div>
						<div>{{ detail.no_outcome_account }}</div>
					</div>
				</div>
			</div>

			<a-divider orientation="left">司机信息</a-divider>
			<a-button class="a_btn" type="primary" @click="visible_driver=true" style="margin-right: 30px;">绑定司机</a-button>
			<a-button class="a_btn" @click="add">新增司机</a-button>
			<div class="table_box">
				<div class="tr_box">

					<div>车牌号</div>
					<div>车牌类型</div>
					<div>司机名称</div>
					<div>联系电话</div>
					<div>操作</div>
				</div>
				<div>
					<div class="td_box" v-for='(item,index) in driverList'>
						<div>{{ item.truck_license }}</div>
						<div>{{ item.type_name}}</div>
						<div>{{ item.chauffeur }}</div>
						<div>{{ item.chauffeur_mobile }}</div>
						<div >
							<a-popconfirm
							    title="是否确认删除该车牌?"
							    ok-text="是"
							    cancel-text="否"
							    @confirm="del_driver(item)"
							  >
							    <a href="#">删除</a>
							  </a-popconfirm>
							
							
							</div>
					</div>
				</div>
			</div>
			<a-divider orientation="left" v-if="detail.flag==1 && detail.empty_barrel.length>0">空桶信息</a-divider>
			<div class="table_box" v-if="detail.flag==1 && detail.empty_barrel.length>0">
				<div class="tr_box">
					<div>可用空桶数量</div>
					<div>可用空桶额度</div>
					<div>拖欠空桶数量</div>
					<div>空桶信用额度</div>
					<div>经销商空桶库存</div>
				</div>
				<div>
					<div class="td_box">
						<div>{{ detail.empty_barrel.availableCount }}</div>
						<div>{{ detail.empty_barrel.availableCreditCount }}</div>
						<div>{{ detail.empty_barrel.borrowCount }}</div>
						<div>{{ detail.empty_barrel.creditCount }}</div>
						<div>{{ detail.empty_barrel.stockCount }}</div>
					</div>
				</div>
			</div>

			<div class="box_form box_form_btns">
				<a-button class="a_btn" type="primary" @click="save_change" style="margin-right: 30px;">保存</a-button>
				<a-button type="defalut" @click="$router.back()">返回</a-button>
			</div>
		</div>
		<SelectDriver select_type="checkbox" @submit="submit_driver" :visible.sync="visible_driver"></SelectDriver>
	</div>
</template>

<script>
	import {
		get_seller_detail,
		save_seller,
		saveSellerDriver,delSellerDriver
	} from '@/api/push.js'

	import SelectDriver from "@/components/SelectDriver";

	export default {
		components: {
			SelectDriver
		},
		data() {
			return {
				driverList: [],
				visible_driver: false,
				detail: {},
				department_tree: []
			}
		},
		created() {
			this.get_info()
			this.$method.get_department().then(res => {
				this.department_tree = [...res];
			});
		},
		methods: {
			del_driver(it){
				delSellerDriver({data:{
					id:it.id
				},info:true}).then(res=>{
					this.get_info()
				})
			},
			saveSellerDriverFun() {
				this.visible_driver= false
				saveSellerDriver({
					data: {
						sales_seller_id: this.$route.query.id,
						driver_info: this.driverList.map(it => {
							return {
								"driver_id": it.driver_id,
								"truck_license": it.truck_license,
								"type": it.type,
							}
						})
					},
					info:true
				}).then(res => {
					this.get_info()
				})
			},
			submit_driver(data) {
				data.data.forEach(it=>{
					this.driverList.push(it)
				})
				this.visible_driver= false
				this.saveSellerDriverFun()
			},
			//添加
			add() {
				this.$router.push({
					path: '/driver/index',
				})
			},
			get_info() {
				get_seller_detail({
					data: {
						sales_seller_id: this.$route.query.id,
					}
				}).then(res => {
					this.detail = res.data.list
                    this.detail.seller_flag = this.detail.seller_flag ? this.detail.seller_flag.split(',').map(item=>item * 1) : []
					this.driverList = res.data.list.driver_info
					this.detail.need_in_range = this.detail.need_in_range + ''
				})
			},
			save_change() {
				save_seller({
					data: {
						sales_seller_id: this.detail.id,
						department_id: this.detail.department_id,
						openid: this.detail.openid,
						phone: this.detail.phone,
						need_in_range: this.detail.need_in_range,
                        seller_flag:this.detail.seller_flag ? this.detail.seller_flag.join(',') : '',
                        self_pickup_auth:this.detail.self_pickup_auth
					},
				}).then(res => {
					this.$message.success(res.info);
					// this.get_info();
					this.$router.back()
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.page_detail {
		margin: 0;
		margin-left: 5%;

	}

	.table_box {
		margin-top: 20px;
		width: 750px;

		.tr_box {
			background-color: #eeeeee;
			display: flex;
			margin-bottom: 15px;
			height: 30px;
			line-height: 30px;
		}

		.tr_box div {
			width: 25%;
		}

		.td_box {
			display: flex;
		}

		.td_box div {
			width: 25%;
			height: 30px;
		}
	}
</style>
<template>
    <div>
        <PopTableList
            :form_data_seo="form_data_seo_pop"
            :form_data="form_data_pop"
            :zIndex="2000"
            :columns="pop_columns"
            :visible.sync="user_visible"
            :rowSelection="rowSelection"
            :get_table_list="get_table_list"
            @submit="submit"
			
			 :slot_table_list="['type']"
            rowKey="plate_id"
        >
        <!-- :submit_preprocessing="submit_preprocessing" -->
            <!-- <template slot="department" slot-scope="data">
                <span>{{ data.record.department_join.department_join }}</span>
            </template> -->
            <template slot="type" slot-scope="data">
                <span v-if="data.text == 1" style="color:rgb(255, 147, 25)">承运/自提</span>
                <span v-else-if="data.text == 2" style="color:#2a82e4">统一配送</span>
            </template>
            <div slot="pop_footer">
                已选择{{ selectedRowKeys.length || 0 }}
            </div>
        </PopTableList>
    </div>
</template>

<script>
import PopTableList from './PopTableList'
import { getSellerSelect} from "@/api/personnel";
import props_pop from './props/pop'
import table_pop from './props/table'
    import {
        getDriverTruckList,driverUnbind
        // outbound_order_export
    } from '@/api/push'
const columns = [
    {
            title: "司机名称",
            dataIndex: "chauffeur",
        },
        {
            title: "车牌",
            dataIndex: "truck_license",
        },
		
		{
		    title: "车牌类型",
			 dataIndex: "type_name",
		},
        {
            title: "联系电话",
            dataIndex: "chauffeur_mobile",
        },
]

export default {
    name: 'SelectSeller',
    components: {
        PopTableList,
    },
    props: {
        ...props_pop,
        ...table_pop,
        select_type: {
            type: String,
            default: 'radio'
        },		
    },

    data() {
        return {
            user_visible: false,
            get_table_list: getDriverTruckList,
            pop_columns: columns,
            // submit_preprocessing: {
            //     array_to_string: ['department_id']
            // },
            form_data_pop: {
                title: '请选择司机'
            },
            form_data_seo_pop: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "text",
                        name: "keyword",
                        title: "关键词",
                        options: {}
                    }
                ]
            },
            select_user: {

            },
            select_user_list: [],
            selectedRowKeys: []
        }
    },
    computed: {
        rowSelection() {
            return {
                onChange: (selectedRowKeys, selectedRows) => {
                    this.selectedRowKeys = selectedRowKeys;
                    if (this.select_type == 'radio') {
                        this.select_user = selectedRows[0];
                    } else {
                        const map = new Map()
                        this.select_user_list = [...this.select_user_list, ...selectedRows].filter(item => {
                            return !map.has(item.plate_id) && map.set(item.plate_id, item.plate_id) && this.selectedRowKeys.indexOf(item.plate_id) > -1
                        });
                    }
                },
                selectedRowKeys: this.selectedRowKeys,
                type: this.select_type,
            };
        },
    },
    watch: {
        visible(new_value) {
            this.user_visible = new_value
        },
        user_visible(new_value) {
            this.$emit('update:visible', new_value)
        },
    },
    created() {
        // Promise.all([this.$method.get_department(), this.$method.get_role()]).then(res => {
        //     this.form_data_seo_pop.list.forEach(item => {
        //         if (item.name == "department_id") {
        //             item.treeData = res[0];
        //         }
        //         if (item.name == "role_id") {
        //             item.list = [...this.$config.status_all, ...res[1]];
        //         }
        //     });
        // })
    },
    methods: {
        submit() {
            this.$emit('submit', {
                data: this.select_type == 'radio' ? this.select_user : this.select_user_list
            })
			this.selectedRowKeys = [];
        }
    }
}
</script>